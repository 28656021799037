import React from "react";
import axios from "axios";
import { APISettings } from "../../model/apisettings";
// export const GetAPISettings = async (roleid: number, callback: any) => {
//     await axios.post("api/Account/SaveandGetApiSettings?userId=" + roleid)
//     .then(res => {
//         callback(res.data)
//     })
//         .catch(err => console.log(err))
// }


export const GetAPISettings = async (id: number, callback: any) => {
    await axios.post("api/Account/SaveandGetApiSettings?userId=" + 10)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}
export const GetNewApiKey = async (id: number, callback: any) => {
    await axios.get("api/Account/GenerateKey?userId=" + 10)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}

export const SaveApiSettings = async (setiings: APISettings, callback: any) => {
    await axios.post("api/Account/SaveApiSettings", {
        id: setiings.id,
        name: setiings.name,
        Url: setiings.url,
        token: setiings.token
    })
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}

export const TestAPiConncetion = async (setiings: APISettings, callback: any) => {
    await axios.post("api/Account/TestConnection", {
        Url: setiings.url,
        token: setiings.token
    })
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}
