import axios from "axios"
import { scheduledPhase } from "../../model/scheduledPhase"

export const GetMagentowebSiteList = async (callback: any) => {
   // debugger;
    await axios.get(`api/MagentoWebSync/GetMagentowebSiteList`).then(result => {
        callback(result.data)
    }).catch(err => {
        console.log(err)
    })
}

export const GetMagentowebSiteListbyJobId = async (jobId:number, callback: any) => {
   // debugger;
    await axios.get(`api/MagentoWebSync/GetMagentowebSiteListByJobId?jobId=${jobId}`).then(result => {
        callback(result.data)
    }).catch(err => {
        console.log(err)
    })
}

export const GetMagentoLatestSyncDate = async (callback: any) => {
    await axios.get("api/MagentoWebSync/GetMagentoLatestSyncDate").then(result => {
        callback(result.data)
    }).catch(err => {
        console.log(err)
    })
}


export const GetRunningJob = async (callback: any) => {
    await axios.get("api/Job/GetRunningJob").then(result => {
        callback(result.data)
    }).catch(err => {
        console.log(err)
    })
}


export const GetMagentoRunningJob = async (callback: any) => {
    await axios.get("api/Job/GetMagentoRunningJob").then(result => {
        callback(result.data)
    }).catch(err => {
        console.log(err)
    })
}


export const PauseSync = async (callback: any) => {
    await axios.get("api/Job/PauseSync").then(result => {
        callback(result.data)
    }).catch(err => {
        console.log(err)
    })
}




export const RefreshChannels = async (callback: any) => {
    await axios.get("api/Website/RefreshChannels").then(result => {
        callback(result.data)
    }).catch(err => {
        console.log(err)
    })
}

export const SyncMagentoWebsiteService = async (selectWebsiteId: number, jobType: number, deltaDate: string,selectedTime:string, callback: any) => {
    await axios.post("api/MagentoWebSync/SyncMagentoWebsiteService", {
        magentoWebsiteId: selectWebsiteId,
        jobType: jobType,
        deltaDate: deltaDate,
        deltaTime:selectedTime

    }).then(result => {
        callback(result.data)
    }).catch(err => {
        console.log(err)
    })
}
export const SyncAllInriverChannelservice = async (autosync: boolean, callback: any) => {
    await axios.get(`api/Website/SyncAllInriverChannelservice?autoSync=${autosync}`).then(result => {
        callback(result.data)
    }).catch(err => {
        console.log(err)
    })
}
export const GetMagentoJobListService = async (page: number, size: number, callback: any) => {
    await axios.post("api/MagentoWebSync/GetMagentoJobListService", {
        index: page,
        recordPerPage: size
    }).then(res => {
        callback(res.data)
        
    })
        .catch(err => console.log(err))
}

export const GetMagentoJobDetailsService = (id: number, callback: any) => {
    if (id != null) {
        axios.get(`api/MagentoWebSync/GetMagentoJobDetailsService?jobId=${id}`).then(result => {
            return callback(result.data);
        }).catch(err => {
            console.log(err)
        })
    }
}
export const FailedMagentojobRetry = (id: number, callback: any) => {
    if (id != null) {
        axios.get(`api/MagentoWebSync/FailedMagentojobRetry?jobId=${id}`).then(result => {
            return callback(result.data);
        }).catch(err => {
            console.log(err)
        })
    }
}




export const GetMagentoTaskDetails = (jobId: number, page: number, size: number, entityType: number, statusId: number, websiteId: number, callback: any) => {
    if (jobId != null) {
        axios.post(`api/MagentoWebSync/GetMagentoTaskDetailsById`, {
            Id: jobId,
            index: page,
            recordPerPage: size,
            entityTypeId: entityType,
            statusId: statusId,
            websiteId: websiteId
        }).then(result => {
            return callback(result.data);
        }).catch(err => {
            console.log(err)
        })
    }
}

export const CancellMagentoJobandTaskService = (id: number, callback: any) => {
    if (id != null) {
        axios.get(`api/MagentoWebSync/CancellMagentoJobandTaskService?jobId=${id}`).then(result => {
            return callback(result.data);
        }).catch(err => {
            console.log(err)
        })
    }
}

export const GetSyncPauseval = (id: number, callback: any) => {
    axios.get(`api/Job/GetSyncPauseval?id=${id}`).then(result => {
        return callback(result.data);
    }).catch(err => {
        console.log(err)
    })

}

export const GetBulkEndPoint = async (callback: any) => {
    await axios.get("api/Job/GetBulkEndPoint").then(result => {
        callback(result.data)
    }).catch(err => {
        console.log(err)
    })
}
export const UpdateBulkEndPoint = (id: number, callback: any) => {
    axios.get(`api/Job/UpdateBulkEndPoint?id=${id}`).then(result => {
        return callback(result.data);
    }).catch(err => {
        console.log(err)
    })
}

export const SaveEntityService = async (entityDetails: scheduledPhase, callback: any) => {
    await axios.post("api/Job/SaveEntityService", {
        id: entityDetails.id,
        name: entityDetails.name,
        
    }).then(res => {
        callback(res.data)
    })
        .catch(err => console.log(err))
}
