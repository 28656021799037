﻿
export interface scheduledSync {
    id: number,
    phaseid: number,
    nextsyncdate: Date,
    ismagentoSync: boolean,
    days: string,
    month: string,
    name: string,
    time: string,
    isActive: number

}


export const createscheduledSync = (): scheduledSync => {
    return ({
        id: 0,
        phaseid: 0,
        nextsyncdate: new Date(),
        ismagentoSync: false,
        days: "",
        month: "",
        name: "",
        time:"",
        isActive: 0

    })
}