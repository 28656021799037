import React, { useState } from "react";
import { user } from "../../dataservices/model/user";
import Header from "../Header";
import { useHistory } from 'react-router-dom';
import { UserRegister } from '../../dataservices/action/account/register';
import { ValidateEmail } from '../../dataservices/helper/componenthelpers'

const Register = () => {

    const [username, setName] = useState("");
    const [password, setPassword] = useState("");
    const [cpassword, setcPassword] = useState("");
    const [emailId, setEmailid] = useState("");
    const history = useHistory();
    const ChangeName = (e: any) => {
        setName(e.target.value)
    }
    const ChangePassword = (e: any) => {
        setPassword(e.target.value)
    }
    const ChangecPassword = (e: any) => {
        setcPassword(e.target.value)
    }
    const changeEmail = (e: any) => {
        setEmailid(e.target.value)
    }

    const Register = () => {
        if (emailId != "" && password != "") {
            if (ValidateEmail(emailId)) {
                if(password === cpassword){
                    UserRegister(username, emailId, password, function (result: user) {
                        if (result.isSuccess) {
                            alert("Registration Created Successfully");
                            history.push(`/`);
                        } else {
                            alert("Registration Creation  Failed");
                        }
                    })
                }
                else {
                    alert("Password and Confirm Password did not match");
                }
            }
            else {
                alert("please enter correct emailid");
            }
        } else {
            alert("*Please fill required field");
        }
    }
    return (
        <div className="section-padding">
            <div className="form-wrapper">
                <h1 className="text-center">Register</h1>
                <form>
                <div className="mb-3">
                        <label className="form-label">Email</label>
                        <input type="email" onChange={changeEmail} placeholder="" className="form-control" required value={emailId}/>
                    </div>
                    {/* <div className="mb-3">
                        <label className="form-label">Username</label>
                        <input type="text" onChange={ChangeName} placeholder="" className="form-control" required />
                    </div> */}
                    <div className="mb-3">
                        <label className="form-label">Password</label>
                        <input type="password" onChange={ChangePassword} placeholder="" className="form-control" required value={password}/>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Confirm Password</label>
                        <input type="password" onChange={ChangecPassword} placeholder="" className="form-control" required  value={cpassword}/>
                    </div>
                    <div className="mb-3 text-center">
                        <input type="button" className="btn btn-primary" onClick={Register} name="Signup" value="Register" />
                    </div>
                </form>
            </div>
        </div>
    )

}

export default Register;