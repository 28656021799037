import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import SideBar from "../sidebar";
import { allwebsites } from "../../dataservices/model/allwebsites";
import { GetAllWebsitesService } from "../../dataservices/action/dashboard/allwebsites";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Loadermessage, CreateinitialLoadermessage } from "../../dataservices/model/loadermessage";
import Loader from "../loader";
import { DeleteWebsiteService } from "../../dataservices/action/dashboard/allwebsites";
import ASuccess from "../alretbox/success";
import AFailed from "../alretbox/failed";

const Dashboard = () => {

    var intallwebsites: allwebsites[] = new Array<allwebsites>();
    const [allwebsites, SetAllWebsites] = useState(intallwebsites)
    const [isloader, Setisloader] = useState(CreateinitialLoadermessage());
    const history = useHistory();

    useEffect(() => {
        GetAllWebsitesService(function (result: any) {
            SetAllWebsites(result)
        })

    }, [])
    const AddWebsite = () => {
        history.push('/addwebsite');
    }
    const DeleteAlert = (chennalid: number, webSiteId: number, websiteName: string) => {
        confirmAlert({
            title: 'Delete Website',
            message: 'Are you sure you want to delete the website "' + websiteName + '"?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => DeleteWebsite(chennalid, webSiteId)
                },
                {
                    label: 'No',
                    onClick:()=>{}
                }
            ]
        });
    }
    const DeleteWebsite = (channelId: number, webSiteId: number) => {
        // debugger;
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        DeleteWebsiteService(channelId, webSiteId, function (res: any) {
            // debugger;
            if (res) {
                let result = CreateinitialLoadermessage();
                result.isSuccess = true;
                result.message = "Website deleted successfully"
                Setisloader(result);
                history.go(0);
            }
        })

    }
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-3">
                    <SideBar />
                </div>
                {

                    isloader.isLoader ? (<Loader isActive={isloader} />) : (null)
                }
                <div className="col-md-9">
                    <div className="d-flex justify-content-between align-items-center mb-4" >
                        <h1 className="m-0">Dashboard</h1>
                        <input className="btn btn-primary" type="button" onClick={AddWebsite} value="+ Add Website" />
                    </div>
                    <div className="row">
                        {
                            allwebsites != null ? (
                                allwebsites.map((item, index) => (
                                    <div className="col-sm-6 col-md-6">
                                        <div className="box-results" key={index}>
                                            <div className="box-results-row">
                                                <div className="box-results-col txt-name">
                                                    Channel Name
                                                </div>
                                                <div className="box-results-col">
                                                    {item.channelsName}
                                                </div>
                                                <div className="box-results-col txt-red">
                                                    <button className="btn btn-sm btn-outline-danger" data-toggle="tooltip" data-placement="top" title="Delete Channel" onClick={(e) => DeleteAlert(item.channelsId, item.websiteId, item.websiteName)} ><i className="bi bi-trash-fill"></i></button>
                                                </div>
                                            </div>
                                            <div className="box-results-row">
                                                <div className="box-results-col txt-name">
                                                    Website Name
                                                </div>
                                                <div className="box-results-col">
                                                    {item.websiteName}
                                                </div>
                                                <div className="box-results-col">

                                                </div>
                                            </div>
                                            <div className="box-results-row">
                                                <div className="box-results-col txt-name">
                                                    Store Name
                                                </div>
                                                <div className="box-results-col">
                                                    {item.storeName}
                                                </div>
                                                <div className="box-results-col" >

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                ))
                            ) : (null)
                        }
                    </div>

                </div>
                <div >
                    {
                        isloader.isfailed ? (
                            <AFailed message={isloader.message} />
                        ) : (null)
                    }
                </div>
                <div >
                    {
                        isloader.isSuccess ? (
                            <ASuccess message={isloader.message} />
                        ) : (null)
                    }
                </div>
            </div>
        </React.Fragment>
    )
}
export default Dashboard;