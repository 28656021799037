import { fail } from "assert"


export interface user {
    id: number,
    userName: string,
    password: string,
    emailId: string,
    isActive: boolean,
    passwordhash: string,
    isSuccess: boolean,
    role: boolean,
    createdon: Date,
    customerId: number
    index: number;
    recordPerPage: number;
    searchValue: string;
    totalRecord: number;
}


export const createinitialUser = (): user => {
    return ({
        id: 0,
        userName: "",
        password: "",
        emailId: "",
        isActive: false,
        passwordhash: "",
        isSuccess: false,
        role: false,
        createdon: new Date(),
        customerId: 0,
        index: 1,
        recordPerPage: 50,
        searchValue: "",
        totalRecord: 0
    })
}

