import React, { useEffect, useState, useRef } from "react";
import SideBar from "../sidebar";
import { ReadFiledate } from "../../dataservices/action/job/sync";
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Loader from "../loader";
import { Loadermessage, CreateinitialLoadermessage } from "../../dataservices/model/loadermessage";
import AFailed from "../alretbox/failed";
import ASuccess from "../alretbox/success";
import { scheduledPhase } from "../../dataservices/model/scheduledPhase";
import { SaveEntityService } from "../../dataservices/action/magentowebsync/magentosync";
const SyncSpecificEntity = () => {

    const [filename, setFilename] = useState();

    const [selectedFile, setSelectedFile] = useState(null);
    const [fileType, setFileType] = useState();
    const inputFile: any = useRef();
    const history = useHistory();
    const [isloader, Setisloader] = useState(CreateinitialLoadermessage());
    const [TypeId, SetTypeID] = useState(1);
    const [TypeValue, SetTypeValue] = useState("");


    const SaveEntity = (e: any) => {
        let syncEntities: scheduledPhase = {
            id: TypeId,
            name: TypeValue
        }
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        if (TypeValue != "") {
            SaveEntityService(syncEntities, function (result: scheduledPhase) {
                let resultmes = CreateinitialLoadermessage();
                if (result != null) {
                    history.push(`/joblist`)
                }
                resultmes.isLoader = false;
                Setisloader(resultmes);
                //Setisloader(false);
                //if (result.isSuccess) {
                //    history.push(`/dashboard`);
                //} else {
                //    setisPopup(true);
                //    setpopupmsg(result.message);
                //}
            })
        }
        else {
            alert("Enter the entityId/Partno values...");
            let resultmes = CreateinitialLoadermessage();
            resultmes.isLoader = false;
            Setisloader(resultmes);
        }

    }

    const handleFileUpload = (e: any) => {
        //debugger;
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        e.preventDefault();
        const { files } = e.target;
        if (files && files.length) {
            setFilename(files[0].name);
            setSelectedFile(files[0]);
            const formData = new FormData();
            formData.append('File', files[0]);
            try {
                Setisloader(load);
                const response = axios({
                    method: "post",
                    url: "api/Website/UploadFile",
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" },
                }).then(result => {
                    let resultmes = CreateinitialLoadermessage();
                    if (result != null) {
                        history.push(`/synchistory`)

                    }
                    resultmes.isLoader = false;
                    Setisloader(resultmes);
                });
                //Setisloader(load);
            } catch (error) {
                console.log(error)
            }
        }
    }

    const OpenFileExplore = () => {
        inputFile.current.click();
    };
    const Back = () => {
        history.push(`/synchistory`);
    }

    const ClosePopup = () => {
        Setisloader(CreateinitialLoadermessage())
    }

    const ChangeType = (TypeId: number) => {

        SetTypeID(TypeId);
    }

    const ChangeValue = (e: any) => {
        debugger;
        SetTypeValue(e.target.value);
    }


    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-3">
                    <SideBar />
                </div>
                {
                    isloader.isLoader ? (<Loader isActive={isloader} />) : (null)
                }
                <div className="col-md-9">
                    <form>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h1>Upload Entities List</h1>
                                    <div>
                                        <input className="btn btn-primary" type="button" onClick={Back} value="Back" />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="mb-3">
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" defaultChecked name="istype" id="" onClick={(e) => ChangeType(1)} />
                                    <label className="form-check-label">
                                        EntityId
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio"  name="istype" id="" onClick={(e) => ChangeType(2)} />
                                    <label className="form-check-label">
                                        PartNumber
                                    </label>
                                </div>

                            </div>
                        </div>
                        <div className="row">
                            <div className="mb-3">
                                <div className="form-check">
                                    <label className="form-label">Enter value : </label>
                                    <textarea className="form-control"
                                        value={TypeValue}
                                        onChange={e => SetTypeValue(e.target.value)}
                                    />
                                </div>

                            </div>
                            <div className="md-3">
                                <div>
                                    <input className="btn btn-primary" type="button" onClick={SaveEntity} value="Save" />
                                </div>
                            </div>
                        </div>
                        {/*<div className="row my-4">*/}
                        {/*    <div className="col-md-4">*/}
                        {/*        <input className="form-control" type="text" value={filename} />*/}
                        {/*        <div className="form-text mt-2">*/}
                        {/*            <a target="_blank" href="/Files/SampleFile.xlsx" download>Download Sample File</a>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className="col-md-3">*/}
                        {/*        <input type="button" className="btn btn-primary" onClick={OpenFileExplore} value="Browse & Upload" />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <input
                            ref={inputFile}
                            style={{ display: "none" }} onChange={handleFileUpload}
                            type="file"
                        />

                    </form>
                </div>
                <div onClick={ClosePopup}>
                    {
                        isloader.isfailed ? (
                            <AFailed message={isloader.message} />
                        ) : (null)
                    }
                </div>
                <div onClick={ClosePopup}>
                    {
                        isloader.isSuccess ? (
                            <ASuccess message={isloader.message} />
                        ) : (null)
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

export default SyncSpecificEntity;