import React from "react";
import axios from "axios";
import { megantoAttributeMapping } from "../../model/megantoAttributeMapping";


export const GetAllAttributes = async (callback: any) => {
    await axios.get("api/Attributes/GetAllAttributes?userId=" + 10)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}
export const GetmagentoAttributeMapping = async (id: number, callback: any) => {
    await axios.get("api/Attributes/GetmagentoAttributeMapping?userId=" + 10)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}

export const GetAllMagentoAttributes = async (callback: any) => {
    await axios.post("api/Attributes/GetAllMagentoAttributes?Userid=" + 10
    )
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}

export const SavePIMAttributeMapping = async (megantoAttributeMapping: megantoAttributeMapping, callback: any) => {
    await axios.post("api/Attributes/SavePIMAttributeMapping", {
        id: megantoAttributeMapping.id,
        attributeCode: megantoAttributeMapping.attributeCode,
        magentoAttributeId: megantoAttributeMapping.magentoAttributeId,
        magentoAttributecode: megantoAttributeMapping.magentoAttributecode,
        magentoAttributeName: megantoAttributeMapping.magentoAttributeName,
        isSync: megantoAttributeMapping.isSync,
        fieldTypeDisplayName: megantoAttributeMapping.fieldTypeDisplayName,
    })
        .then(res => {
            callback(res.data)
        })

        .catch(err => console.log(err))
    }

    export const DeleteMagentoAttributeMappingById = async (megantoAttributeMapping: megantoAttributeMapping, callback: any) => {
        await axios.post("api/Attributes/DeleteMagentoAttributeMappingById", {
            id: megantoAttributeMapping.id,
        })
            .then(res => {
                callback(res.data)
            })

            .catch(err => console.log(err))

    }