import axios from "axios"

export const GetAllWebsitesService = async (callback: any) => {
    await axios.get("api/Website/GetAllWebsites").then(result => {
         callback(result.data)
    }).catch(err => {
        console.log(err)
    })
}

export const DeleteWebsiteService = async (clId: number, webId: number, callback: any) => {
   // debugger;
    await axios.post(`api/Website/DeleteWebsiteByIds?channelId=${clId}&&websiteId=${webId}`)
    .then(result => {
        callback(result.data)
    })
        .catch(err => console.log(err))
}