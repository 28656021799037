import React, { Component, useState, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { ForgotPasswordservice } from "../../dataservices/action/account/forgotPassword";
import { user } from "../../dataservices/model/user";
import { useHistory } from 'react-router-dom';
import { ValidateEmail } from '../../dataservices/helper/componenthelpers'
function ForgotPassword() {
    const [emailId, setEmail] = useState("")
    const store = useSelector((state: any) => { return state })
    const history = useHistory();
    const dispatch = useDispatch();
    const ChangeEmail = (e: any) => {
        setEmail(e.target.value)
    }
    const ForgotPassword = () => {
        if (ValidateEmail(emailId)) {
            ForgotPasswordservice(emailId, function (result: user) {
                if (result != null) {
                    dispatch({
                        type: "FORGOTPASSWORD_COMPLETED",
                        payload: result,
                    })
                    alert("Password send to your email");
                    history.push(`/`);
                }
            })
        }
        else {
            alert("please enter correct emailid");
        }

    }

    return (
        <div className="section-padding">
            <div className="form-wrapper">
                <h1 className="text-center">Forgot Password</h1>
                <form>
                    <div className="mb-3">
                        <label className="form-label">Email</label>
                        <input type="text" onChange={ChangeEmail} placeholder="" className="form-control" />
                    </div>

                    <div className="text-center">
                        <input className="btn btn-primary" type="button" onClick={ForgotPassword} value="Send" />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ForgotPassword;