import axios from 'axios';
import { user } from '../../model/user';



export const ForgotPasswordservice = async  (emailId: string, callback: any) => {
    if (emailId != "" ) {
        await axios.post(`http://localhost:31457/api/Account/ForgotPassword`, {
            EmailId: emailId
        }).then(result => {
            return callback(result.data);
        }).catch(err => {
            console.log(err)
        })
    }
}

