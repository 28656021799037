import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import SideBar from "../sidebar";
import { channellist } from "../../dataservices/model/channellist";
import Box from '@material-ui/core/Box'
import { GetChannellistService, RefreshChannels, GetRunningJob, SyncAllInriverChannelservice } from "../../dataservices/action/dashboard/channellist";
import Loader from "../loader";
import { SyncByChannelIdservice } from "../../dataservices/action/dashboard/channellist";
import ASuccess from "../alretbox/success";
import AFailed from "../alretbox/failed";



const SyncConnector = () => {
    var intchannelsiteslist: channellist[] = new Array<channellist>();
    const [channellist, Setchannellist] = useState(intchannelsiteslist);
    const [isloader, setisloader] = useState(false);
    const [isJobRunnning, setisJobRunnning] = useState(false);
    const [isSuccess, setSuccess] = useState(false);

    const history = useHistory();
    const GetJob = () => {
        history.push(`/joblist`);
    }
    const SyncEntities = () => {
        history.push(`/syncentities`);
    }
    const SyncSchedule = () => {
        history.push(`/synschedule`);
    }
    useEffect(() => {
        setisloader(true);
        GetChannellistService(function (result: any) {
            setisloader(false);
            Setchannellist(result)
        })
    }, [])

    useEffect(() => {
        setisloader(true);
        GetRunningJob(function (result: any) {
            setisJobRunnning(result)
            setisloader(false);
        })
    }, [])


    const RefreshChannel = () => {
        setisloader(true);
        RefreshChannels(function (result: any) {
            Setchannellist(result)
            setisloader(false);
        })
    }
    const SyncByChannelId = (channelId: number, autosync: boolean) => {
        setisloader(true);
        SyncByChannelIdservice(channelId, autosync, function (res: any) {
            setisloader(false);
            history.push(`/joblist`);
        })
    }
    const SyncAllInriverChannel = (autosync: boolean) => {
        setisloader(true);
        SyncAllInriverChannelservice(autosync, function (res: any) {
            setisloader(false);
            history.push(`/joblist`);
        })
    }
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-3">
                    <SideBar />
                </div>
                {
                    isloader ? (<Loader isActive={isloader} />) : (null)
                }
                <div className="col-md-9">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex justify-content-end align-items-center mb-3">
                                <input className="btn btn-primary me-2" type="button" onClick={SyncSchedule} value="Sync Schedule" />
                                <input className="btn btn-primary me-2" disabled={isJobRunnning} type="button" onClick={SyncEntities} value="Sync Specific Entities" />
                                <input className="btn btn-primary me-2" disabled={isJobRunnning} type="button" onClick={(e) => SyncAllInriverChannel(false)} value="InRiver Sync All" />
                                <input className="btn btn-primary me-2" disabled={isJobRunnning} type="button" onClick={(e) => SyncAllInriverChannel(true)} value="InRiver & Magento Sync All" />
                                <input className="btn btn-primary" type="button" onClick={GetJob} value="Job List" />
                            </div>
                        </div>
                    </div>
                    <h1>InRiver Sync</h1>
                    <div className="row">
                        <div className="col-md-12">
                            <input className="btn btn-sm btn-primary mb-3" type="button" onClick={RefreshChannel} value="Refresh" />
                        </div>
                    </div>

                    <div className="row">
                        {
                            channellist != null ? (
                                channellist.map((item, index) => (
                                    <div className="col-sm-6 col-md-6">
                                        <div className="box-results" key={index}>
                                            <div className="box-results-row">
                                                <div className="box-results-col txt-name">
                                                    Channel Name
                                                </div>
                                                <div className="box-results-col">
                                                    {item.name}
                                                </div>
                                            </div>
                                            <div className="box-results-row mt-3">
                                                <input className="btn btn-sm btn-outline-secondary float-start " disabled={isJobRunnning} type="button" value="InRiver Sync" onClick={(e) => (SyncByChannelId(item.resultid, false))} />
                                                <input className="btn btn-sm btn-outline-secondary float-end " disabled={isJobRunnning} type="button" value="Inriver & Magento Sync" onClick={(e) => (SyncByChannelId(item.resultid, true))} />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (null)
                        }
                    </div>
                </div>
            </div>
        </React.Fragment >

    )
}
export default SyncConnector;