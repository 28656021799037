import * as React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import './custom.css'
import { useHistory } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/account/register';
import ForgotPassword from './components/account/ForgotPassword';
import LoadUsers from './components/Users/usersindex'
import Connector from './components/magento/connector'
import JobList from './components/sync/joblist';
import SyncConnector from './components/sync/connector';
import SyncSpecificEntity from './components/sync/syncentities';
import JobDetails from './components/sync/jobdetails';
import Dashboard from './components/dashboard/dashboard';
import AddWebsite from './components/dashboard/addwebsite';
import Globals from './dataservices/global';
import TakDetails from './components/sync/taskdetails';
import ScheduleComponent from './components/sync/schedule';
import loginLayout from './components/loginLayout';
import AddAttribute from './components/attribute/addattribute';
import MagentoSyncConnector from './components/magentosync/connector'
import MagentoJobList from './components/magentosync/jobList';
import MagentoJobDetails from './components/magentosync/jobdetails';
import MagentoTakDetails from './components/magentosync/taskdetails';
import MagentoScheduleComponent from './components/magentosync/schedule';

export default () => (
    <Layout >
        <Route exact path='/register' component={Register} />
        <Route exact path='/userindex' component={LoadUsers} />
        <Route exact path='/apiSettings' component={Connector} />
        <Route exact path='/joblist' component={JobList} />
        <Route exact path='/synchistory' component={SyncConnector} />
        <Route exact path='/syncentities' component={SyncSpecificEntity} />
        <Route exact path='/jobDetails' component={JobDetails} />
        <Route exact path='/dashboard' component={Dashboard} />
        <Route exact path='/addwebsite' component={AddWebsite} />
        <Route exact path='/addattribute' component={AddAttribute} />
        <Route exact path='/tasklist' component={TakDetails} />
        <Route exact path='/synschedule' component={ScheduleComponent} />        
        <Route exact path='/forgotPassword' component={ForgotPassword} />
        <Route exact path="/" component={Login} />
        <Route exact path="/magentosynchistory" component={MagentoSyncConnector} />
        <Route exact path='/magentojoblist' component={MagentoJobList} />
        <Route exact path='/magentojobdetails' component={MagentoJobDetails} />
        <Route exact path='/magentotakdetails' component={MagentoTakDetails} />
        <Route exact path='/MagentoSyncSchedule' component={MagentoScheduleComponent} />
        
        {/* <Route path='/counter' component={Counter} />
        <Route path='/fetch-data/:startDateIndex?' component={FetchData} /> */}
    </Layout>
);    

