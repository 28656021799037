export interface Loadermessage {
    message: string,
    isLoader: boolean,
    isSuccess: boolean,
    isfailed : boolean
}



export const CreateinitialLoadermessage= (): Loadermessage => {
    return ({
        message: "",
        isLoader: false,
        isSuccess: false,
        isfailed : false
    })
}
