import React, { Component, useState, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from 'react-redux'
import Header from "./Header";
import { user, createinitialUser } from '../dataservices/model/user';
import { LoginService } from '../dataservices/action/login';
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import AFailed from "./alretbox/failed";
import Loader from "./loader";
function Login() {
    const initaluser = createinitialUser();
    const [username, setName] = useState("")
    const [password, setPassword] = useState("")
    const [islogin, setislogin] = useState(true)
    const [isLoader, setisLoader] = useState(false);

    const store = useSelector((state: any) => { return state })
    const history = useHistory();
    const dispatch = useDispatch();
    const ChangeName = (e: any) => {
        setName(e.target.value)
    }
    const CloseLoginPopup = () => {
        setislogin(true);
    }
    const ChangePassword = (e: any) => {
        setPassword(e.target.value)
    }
    // const showToastMessage = () => {
    //     toast.success('Success Notification !', {
    //         position: toast.POSITION.TOP_RIGHT
    //     });
    // };
    const Login = () => {
        setisLoader(true);
        LoginService(username, password, function (result: user) {
            if (result.isSuccess) {
                //alert("Login Success");
                // toast.success('Success Notification !', {
                //     position: toast.POSITION.TOP_RIGHT
                // });
                dispatch({
                    type: "LOGIN_COMPLETED",
                    payload: result
                })
                dispatch({
                    type: "LOG_IN_AUTH",
                    payload: true
                })
                setisLoader(true);
                history.push(`/dashboard`);

            }
            else {
                setislogin(false);
                setisLoader(false);
            }
        })
    }

    const handleSubmit = () => {
                 setisLoader(true);
        LoginService(username, password, function (result: user) {
            if (result.isSuccess) {
                //alert("Login Success");
                // toast.success('Success Notification !', {
                //     position: toast.POSITION.TOP_RIGHT
                // });
                dispatch({
                    type: "LOGIN_COMPLETED",
                    payload: result
                })
                dispatch({
                    type: "LOG_IN_AUTH",
                    payload: true
                })
                setisLoader(true);
                history.push(`/dashboard`);

            }
            else {
                setislogin(false);
                setisLoader(false);
            }
        })
    }
    const onFormSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();

    }
    return (
        <div className="section-padding">
            {isLoader ? (<Loader isActive={isLoader} />) : (null)}
            <Header />
            <div className="form-wrapper">
                <h1 className="text-center">Login</h1>
                <form onSubmit={onFormSubmit} >
                    <div className="mb-3">
                        <label className="form-label">Email</label>
                        <input type="text" onChange={ChangeName} placeholder="" className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Password</label>
                        <input type="password" onChange={ChangePassword} placeholder="" className="form-control" />
                    </div>
                    {/*< div className="mb-3">
                        <Link to="/forgotpassword">Forgot Password?</Link>
                    </div>*/}
                    <div className="mb-3 text-center">
                        <input type="submit" className="btn btn-primary" onClick={Login} name="Login" value="Login" />
                    </div>
                    {/* <button onClick={showToastMessage}>Notify</button>
                    <ToastContainer /> */}
                </form>
            </div>
            <div onClick={CloseLoginPopup}>
                {

                    !islogin ? (
                        <AFailed message={"Login Failed"} />
                    ) : (null)
                }

            </div>
        </div>
    )
}

export default Login;