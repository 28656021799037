import internal, { fail } from "assert"


export interface allwebsites {
    magentoWebsiteId: number,
    channelsId: number,
    websiteId: number,
    websiteCode: string,
    websiteName: string,
    storeId: number,
    storeCode: string,
    storeName: string,
    storeViewId: number,
    storeViewCode: string,
    storeViewName: string,
    categoryId: number,
    categoryName: string,
    channelsName: string
    id: number,
    isSuccess: boolean,
    message: string,
    deltaDate: Date,
    LastestSyncDeltaDate:Date
}


export const createinitialallwebsites = (): allwebsites => {
    return ({
        magentoWebsiteId: 0,
        channelsId: 0,
        websiteId: 0,
        websiteCode: "",
        websiteName: "",
        storeId: 0,
        storeCode: "",
        storeName: "",
        storeViewId: 0,
        storeViewCode: "",
        storeViewName: "",
        categoryId: 0,
        categoryName: "",
        channelsName: "",
        id: 0,
        isSuccess: false,
        message: "",
        deltaDate: new Date(),
        LastestSyncDeltaDate: new Date()
    })
}

